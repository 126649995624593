<template>
  <v-dialog v-model="dialogResend" max-width="500px">
    <v-card>
      <v-card-title class="text-h6"
        >A DocuSign document will be sent to:</v-card-title
      >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="item.email"
                outlined
                readonly
                solo
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click.native="closeResend"
          >Cancel</v-btn
        >
        <v-btn color="blue darken-1" text @click.native="resendDocumentConfirm"
          >OK</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-fade-transition>
      <v-overlay opacity="0.7" color="white" :value="isLoading">
        <v-progress-circular
          indeterminate
          size="70"
          width="10"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </v-fade-transition>
  </v-dialog>
</template>
<script>
import { eventBus } from "../../main";
import axios from "axios";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "ResendDocument",

  data: () => ({
    dialogResend: false,
    isLoading: false,
    item: { email: "" },
    auth_data: {
      access_token: null,
      account_id: null,
    },
  }),

  watch: {
    dialogResend(val) {
      val || this.closeResend();
    },
  },

  methods: {
    closeResend() {
      this.dialogResend = false;
    },

    async resendDocumentConfirm() {
      this.isLoading = true;
      try {
        const {
          data: { access_token, account_id },
        } = await axios.get(process.env.VUE_APP_GET_DOCUSIGN_TOKEN, {
          params: {
            customer: this.item.PartitionKey,
          },
        });

        const auth_data = { access_token, account_id };
        localStorage.setItem("auth_data", JSON.stringify(auth_data));

        const customer_info = {
          customer: {
            PartitionKey: this.item.PartitionKey,
            RowKey: this.item.PartitionKey,
          },
          auth_data,
        };

        const { data } = await axios.get(
          process.env.VUE_APP_GET_DOCUSIGN_FILE,
          {
            params: {
              customer_info,
            },
          }
        );

        if (data.reason) {
          console.log(data.message);
          eventBus.$emit("showSnackBar", data.message, "red");
        } else {
          localStorage.setItem("envelope_id", JSON.stringify(data.envelope_id));

          let response3 = await axios.post(
            process.env.VUE_APP_SEND_DOCUSIGN_FILE,
            {
              docusign_url: data.redirect_url,
              email: this.item.email,
            }
          );

          if (response3.data.success) {
            console.log(response3.data.message);
            eventBus.$emit("showSnackBar", "Document sent!", "green");
          } else {
            console.log(response3.data.message);
            eventBus.$emit("showSnackBar", response3.data.message, "red");
          }
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        if (error.response) {
          console.log(error.response.data);
          eventBus.$emit("showSnackBar", error.response.data, "red");
        } else if (error.request) {
          console.log(error.request);
          eventBus.$emit("showSnackBar", error.request, "red");
        } else {
          console.log("Error", error.message);
          eventBus.$emit("showSnackBar", error.message, "red");
        }
      }

      this.isLoading = false;
      this.closeResend();
    },
  },

  created() {
    eventBus.$on("showResendDialog", (item) => {
      console.log(item);
      this.dialogResend = true;
      this.item = item;
    });
  },
};
</script>
