<template>
  <v-dialog v-model="dialogDelete" max-width="500px">
    <v-card>
      <v-card-title class="text-h6"
        >Are you sure you want to delete this application?</v-card-title
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click.native="closeDelete"
          >Cancel</v-btn
        >
        <v-btn color="blue darken-1" text @click.native="deleteItemConfirm"
          >OK</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { eventBus } from "../../main";
import axios from "axios";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "DeleteCustomerApplication",

  data: () => ({
    dialogDelete: false,
    item: null,
  }),

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    closeDelete() {
      this.dialogDelete = false;
    },

    deleteItemConfirm() {
      // Make delete endpoint
      const customer = this.item;
      customer["disabled"] = true;
      const payload = {
        customer: JSON.stringify(customer),
      };
      axios.post(process.env.VUE_APP_UPDATE_CUSTOMER, payload).then(() => {
        eventBus.$emit("showSnackBar", "Successfully deleted!", "green");
        eventBus.$emit("updateCustomerTable");
      });
      this.closeDelete();
    },
  },

  created() {
    eventBus.$on("showDeleteDialog", (item) => {
      this.dialogDelete = true;
      this.item = item;
    });
  },
};
</script>
